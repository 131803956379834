import { cn } from 'class-merge';
import Image from 'next/image';
import type { FC } from 'react';
import VideoPlayer from '~components/video-player';
import useIsMobile from '~libs/use-is-mobile';
import { S3_BUCKET_IMAGES } from '~constants/etc';

const Presentation: FC = () => {
  const isMobile = useIsMobile();

  if (isMobile) return null;
  return (
    <div
      className={cn(
        'h-full w-full bg-cover bg-center bg-no-repeat px-10 xl:h-[450px] xl:px-[100px]',
      )}
      style={{
        backgroundImage: `url(${S3_BUCKET_IMAGES}/home-video-bg.webp)`,
      }}
    >
      <div className="flex items-center justify-center gap-x-10">
        <div className="relative hidden h-[300px] w-full max-w-[512px] xl:block xl:h-[450px]">
          <Image
            alt="home-video-main"
            draggable={false}
            fill
            src={`${S3_BUCKET_IMAGES}/home-video-main.webp`}
          />
        </div>
        <VideoPlayer
          classNames="h-full w-full w-[550px] xl:w-[680px]"
          src="/videos/home-video.mp4"
        />
      </div>
    </div>
  );
};
export default Presentation;
