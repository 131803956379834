import { cn } from 'class-merge';
import Image from 'next/image';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMobile from '~libs/use-is-mobile';
import { S3_BUCKET_IMAGES } from '~constants/etc';

interface AffiliateProps {
  showDetail?: boolean;
}

const Affiliate: FC<AffiliateProps> = ({ showDetail = false }) => {
  const { t } = useTranslation('home');
  const isMobile = useIsMobile();

  return (
    <div
      className="mt-10 min-h-[250px] w-full bg-cover bg-center bg-no-repeat px-4 md:mt-0 md:xl:px-[100px] xl:min-h-[500px]"
      style={{
        backgroundImage: `url(${S3_BUCKET_IMAGES}/affiliate-bg.webp)`,
      }}
    >
      <div className="relative flex items-center justify-center gap-5 md:gap-10">
        <div
          className={cn(
            showDetail ? 'hidden md:block' : 'hidden lg:block',
            'relative h-[200px] w-full max-w-[50%] md:h-[300px] md:max-w-[300px]',
          )}
        >
          <div className="absolute left-4 top-1/2 h-[150px] w-[100px] -translate-y-14 md:left-0 md:top-0 md:h-[300px] md:w-[200px] md:translate-y-0">
            <Image
              alt="mobile-left"
              draggable={false}
              fill
              src={`${S3_BUCKET_IMAGES}/affiliate-mobile-left.webp`}
            />
          </div>
          <div className="absolute right-2 top-1/2 h-[120px] w-20 -translate-y-1/2 md:right-0 md:top-0 md:h-[240px] md:w-[160px] md:translate-y-0">
            <Image
              alt="mobile-right"
              draggable={false}
              fill
              src={`${S3_BUCKET_IMAGES}/affiliate-mobile-right.webp`}
            />
          </div>
        </div>

        <div
          className={cn(
            showDetail ? 'block' : 'hidden md:block',
            'w-full min-w-[180px] max-w-[250px] md:max-w-[450px]',
          )}
        >
          <Image
            alt="logo"
            draggable={false}
            height={80}
            priority
            src={`${S3_BUCKET_IMAGES}/logo.webp`}
            width={120}
          />
          <h2 className="text-2xl font-bold md:text-4xl">
            {t('affiliate.title')}
          </h2>
          <p className="mt-2 text-xs md:text-base">
            {t('affiliate.description')}
          </p>
        </div>

        <div className="relative h-[220px] w-full max-w-[50%] md:h-[400px] md:max-w-[300px] xl:h-[524px] xl:max-w-[380px]">
          <Image
            alt="affiliate-bg"
            draggable={false}
            fill
            src={`${S3_BUCKET_IMAGES}/${
              isMobile ? 'affiliate-model-mobile.webp' : 'affiliate-model.webp'
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default Affiliate;
