import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import type { FC } from 'react';
import { S3_BUCKET_IMAGES } from '~constants/etc';

const CategoryContainer: FC = () => {
  const { t } = useTranslation();

  const products = [
    {
      key: 'casino',
      label: 'live casino',
    },
    {
      key: 'sport',
      label: 'sport',
    },
    {
      key: 'gaming',
      label: 'slot',
    },
    {
      key: 'lotto',
      label: 'lotto',
    },
  ];

  return (
    <div className="relative mt-20 h-[700px] w-full">
      <div className="absolute bottom-0 z-20 h-[500px] w-full space-y-10">
        <h2 className="text-center text-4xl font-bold">
          {t('home:products.title')}
        </h2>
        <div className="grid grid-cols-2 gap-4 px-10 xl:px-[100px]">
          {products.map((item) => (
            <div
              className="bg-dark border-primary relative h-[250px] overflow-hidden rounded-2xl border-2 bg-cover bg-center bg-no-repeat p-6"
              key={item.key}
              style={{
                backgroundImage: `url(${S3_BUCKET_IMAGES}/procuct-${item.key}-bg.webp)`,
              }}
            >
              <p className="relative z-10 max-w-[160px] text-left text-4xl font-bold uppercase">
                {item.label}
              </p>
              <div className="absolute right-0 top-0 z-0 h-full w-[280px]">
                <Image
                  alt={`${item.key}model`}
                  className="object-cover"
                  draggable={false}
                  height={250}
                  src={`${S3_BUCKET_IMAGES}/procuct-${item.key}-model.webp`}
                  width={250}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="absolute top-0 flex h-[500px] w-full justify-center bg-cover"
        style={{
          backgroundImage: `url(${S3_BUCKET_IMAGES}/products-bg.webp)`,
        }}
      >
        <Image
          alt="product-model"
          className="opacity-80"
          draggable={false}
          height={440}
          src={`${S3_BUCKET_IMAGES}/product-model.webp`}
          width={580}
        />
        <div className="to-default absolute bottom-0 h-[350px] w-full bg-gradient-to-b from-transparent" />
      </div>
    </div>
  );
};

export default CategoryContainer;
