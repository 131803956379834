'use client';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { proxied } from 'radash';
import type { GameProviderInterface } from '../../types/provider';
import CategoryContainer from './category-container';
import PopularGames from './popular-game-container';
import { ProviderEnum } from '~constants/menus';
import useIsMobile from '~libs/use-is-mobile';
import 'swiper/css';
import 'swiper/css/navigation';
import type { AgentInfoResponseInterface } from '~libs/get-agent-info';
import { DEFAULT_CURRENCY, ProviderTypeEnum } from '~constants/etc';
import PageUrls from '~constants/page-urls';
import { useRouter } from '~hooks/use-router';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import SportProviderContainer from '~containers/home/sport-provider-container';
import ProductMobileViewContainer from '~containers/home/product-mobile-view-container';
import AllProviderContainer from '~containers/home/all-provier-container';
import { useGameWithProviders, useProviders } from '~hooks/use-providers';
import UnauthorizedAlertWidget from '~components/widgets/unauthorized-alert-widget';
import { ClientSideRender } from '~components/layouts/client-side-render';
import { useDialog } from '~hooks/use-dialog';
import AllowedProviderWidget from '~components/widgets/allowed-provider-widget';
import { getAllowedProvider } from '~containers/promotions/allowed-provider-container';
import { useAbilities } from '~contexts/abilities';
import getPathLottoUrl from '~libs/get-path-lotto-url';
import HttpErrorHandler from '~components/http-error-handler';

const Products: FC<{
  agentInfo: AgentInfoResponseInterface;
  region: string;
}> = ({ agentInfo, region }) => {
  const { t } = useTranslation(['game-categories', 'common']);
  const isMobile = useIsMobile();
  const { ability } = useAbilities();
  const router = useRouter();
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const [dialog, contextHolderDialog] = useDialog();
  const { data: casinos } = useProviders({
    type: ProviderEnum.CASINO,
    currency,
  });
  const { data: gaming } = useProviders({
    type: ProviderEnum.GAMING,
    currency,
  });
  const { data: sports } = useProviders({
    type: ProviderEnum.SPORTSBOOK,
    currency,
  });
  const { data: lotto } = useGameWithProviders({
    type: ProviderEnum.LOTTO,
    currency,
  });

  const allProviders = useMemo(() => {
    return [
      ...(casinos?.map((res) => ({
        ...res,
        category: ProviderTypeEnum.Casino,
      })) || []),
      ...(gaming?.map((res) => ({
        ...res,
        category: ProviderTypeEnum.Gaming,
      })) || []),
      ...(sports?.map((res) => ({
        ...res,
        category: ProviderTypeEnum.Sportsbook,
      })) || []),
    ];
  }, [casinos, gaming, sports]);

  const providerData = useMemo(() => {
    return {
      all: {
        key: 'all',
        name: t('common:all'),
        icon: '',
        data: allProviders,
      },
      [ProviderEnum.CASINO]: {
        key: ProviderEnum.CASINO,
        name: t('common:menu.casino'),
        data:
          casinos?.map((res) => ({
            ...res,
            category: ProviderTypeEnum.Casino,
          })) || [],
      },
      [ProviderEnum.GAMING]: {
        key: ProviderEnum.GAMING,
        name: t('common:menu.gaming'),
        data:
          gaming?.map((res) => ({
            ...res,
            category: ProviderTypeEnum.Gaming,
          })) || [],
      },
      [ProviderEnum.SPORT]: {
        key: ProviderEnum.SPORT,
        name: t('common:menu.sportsbook'),
        data:
          sports?.map((res) => ({
            ...res,
            category: ProviderTypeEnum.Sportsbook,
          })) || [],
      },
      [ProviderEnum.LOTTO]: {
        key: ProviderEnum.LOTTO,
        name: t('common:menu.lotto'),
        data:
          lotto?.map((res) => ({
            ...res,
            category: ProviderTypeEnum.Lotto,
            title: t('game-categories:lotto'),
          })) || [],
      },
    };
  }, [allProviders, casinos, gaming, sports, lotto]);

  // const handleOpenSportsbook = async (
  //   game: ProviderSportResApiInterface,
  // ): Promise<void> => {
  //   if (!userInfo) {
  //     return dialog.content({
  //       children: (
  //         <ClientSideRender>
  //           <UnauthorizedAlertWidget onClose={() => dialog.destroy()} />
  //         </ClientSideRender>
  //       ),
  //     });
  //   }
  //   try {
  //     const currencyFiltered = game.currencies
  //       .filter((x) => x.includes(currency))
  //       .at(0);
  //     window.open(
  //       await onOpenSportURL(game.id, currencyFiltered, walletInfo?.objectId),
  //       '_blank',
  //     );
  //   } catch (e) {
  //     HttpErrorHandler(e);
  //   }
  // };

  // const handleOpenCasino = (game: ProviderCasinoResApiInterface): void => {
  //   if (!userInfo) {
  //     return dialog.content({
  //       children: (
  //           <ClientSideRender>
  //             <UnauthorizedAlertWidget onClose={() => dialog.destroy()} />
  //           </ClientSideRender>
  //       ),
  //     });
  //   }
  //   try {
  //     const currencyFiltered = game.currencies
  //         .filter((x) => x.includes(currency))
  //         .at(0);
  //     const queryString = objectToQueryString({
  //       category: ProviderTypeEnum.Casino,
  //       providerId: game.id,
  //       currency: currencyFiltered,
  //       walletId: walletInfo?.objectId,
  //     });
  //     router.push(`/${locale}${PageUrls.OpenGame}?qs=${encrypt(queryString)}`);
  //   } catch (e) {
  //     HttpErrorHandler(e);
  //   }
  // };

  const handleOpenLotto = async (
    value: GameProviderInterface,
  ): Promise<void> => {
    if (!userInfo) {
      return;
    }
    try {
      window.open(
        await getPathLottoUrl(
          value.provider,
          currency,
          walletInfo?.objectId,
          value?.gameCode,
        ),
        '_blank',
      );
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const actionOpenProvider = proxied((prop: ProviderEnum) => {
    switch (prop) {
      case ProviderEnum.CASINO:
        return (provider) => {
          router.push(`${PageUrls.Casino}/${provider.id}`);
        };
      case ProviderEnum.GAMING:
        return (provider) => {
          router.push(`${PageUrls.Gaming}/${provider.id}`);
        };
      case ProviderEnum.SPORTSBOOK:
        return (provider) => {
          router.push(`${PageUrls.Sportsbook}/${provider.id}`);
        };
      case ProviderEnum.LOTTO:
        return (provider) => {
          handleOpenLotto(provider);
        };
      default:
        return () => null;
    }
  });

  const handleOpenProvider = (game) => {
    if (!userInfo) {
      return dialog.content({
        children: (
          <ClientSideRender>
            <UnauthorizedAlertWidget onClose={() => dialog.destroy()} />
          </ClientSideRender>
        ),
      });
    }
    if (!ability.can('read', game.type, game.id)) {
      return dialog.content({
        maskable: true,
        className: 'w-full h-fit p-0 rounded flex items-center',
        children: (
          <ClientSideRender>
            <AllowedProviderWidget
              onClose={() => {
                dialog.destroy();
              }}
              providers={getAllowedProvider(
                ability,
                walletInfo?.allowedGameProviders || [],
              )}
              withActivePromotion
            />
          </ClientSideRender>
        ),
      });
    }
    actionOpenProvider[game.type](game);
  };

  const actionOpenGame = proxied((prop: ProviderTypeEnum) => {
    switch (prop) {
      case ProviderTypeEnum.Casino:
        return (provider: any) => {
          handleOpenProvider(provider);
        };
      case ProviderTypeEnum.Gaming:
        return (provider: any) => handleOpenProvider(provider);
      case ProviderTypeEnum.Sportsbook:
        return (provider: any) => {
          handleOpenProvider(provider);
        };
      case ProviderTypeEnum.Lotto:
        return (provider: any) => {
          handleOpenProvider(provider);
        };
      default:
        throw new Error('The category you are looking for cannot be found.');
    }
  });

  if (isMobile) {
    return (
      <>
        {contextHolderDialog}
        <ProductMobileViewContainer
          agentInfo={agentInfo}
          onSelectProvider={(provider) => {
            actionOpenGame[provider.category](provider);
          }}
          providerData={providerData}
          region={region}
        />
      </>
    );
  }

  return (
    <div className="mx-auto w-full max-w-[2000px]">
      {contextHolderDialog}
      <CategoryContainer />
      <SportProviderContainer
        onOpenProvider={(provider) => {
          actionOpenGame[ProviderEnum.SPORTSBOOK](provider);
        }}
        sportProviders={sports}
      />
      <AllProviderContainer
        onSelectProvider={(provider) => {
          actionOpenGame[provider.category](provider);
        }}
        providers={allProviders}
      />
      <PopularGames />
    </div>
  );
};

export default Products;
