'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import type { FC } from 'react';
import { proxied } from 'radash';
import {
  CurrencyEnum,
  DEFAULT_CURRENCY,
  S3_BUCKET_IMAGES,
} from '~constants/etc';

const CustomCarousel: FC = () => {
  const carouselData = proxied((prop: CurrencyEnum) => {
    switch (prop) {
      case CurrencyEnum.THB:
        return Array.from({ length: 5 }).map((_, i) => {
          return (
            <SwiperSlide className="h-full w-full" key={i}>
              <div
                className="relative h-full min-h-[130px] w-full bg-contain bg-left bg-no-repeat md:min-h-[240px] md:bg-cover lg:min-h-[340px] 2xl:min-h-[500px] 2xl:bg-bottom"
                style={{
                  backgroundImage: `url(${S3_BUCKET_IMAGES}/slide-${i + 1}.webp)`,
                }}
              />
            </SwiperSlide>
          );
        });
      case CurrencyEnum.VND:
        return Array.from({ length: 6 }).map((_, i) => {
          return (
            <SwiperSlide className="h-full w-full" key={i}>
              <div
                className="relative h-full min-h-[130px] w-full bg-contain bg-left bg-no-repeat md:min-h-[240px] md:bg-cover lg:min-h-[340px] 2xl:min-h-[500px] 2xl:bg-bottom"
                style={{
                  backgroundImage: `url(${S3_BUCKET_IMAGES}/carousel-${i + 1}.webp)`,
                }}
              />
            </SwiperSlide>
          );
        });
      default:
        return <div />;
    }
  });

  return (
    <Swiper
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      className="relative z-0 h-full w-full"
      loop
      modules={[Pagination, Autoplay]}
      pagination={{ clickable: true }}
      slidesPerView={1}
      spaceBetween={0}
    >
      {carouselData[DEFAULT_CURRENCY]}
    </Swiper>
  );
};

export default CustomCarousel;
